@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }
}

@font-face {
  font-family: "Providence";
  src: local("Providence"), url("./fonts/Providence.ttf") format("truetype");
}

body {
  font-family: "Providence";
  color: #555;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.wrapper {
  width: 100%;
  height: 100vh;
  background: #fff;
  position: relative;
  margin: 0;
  overflow-y: hidden;
}

.wrapper.darkMode {
  background: #111;
}

.content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.content::-webkit-scrollbar {
  width: 0;
}

.container {
  width: 40%;
  margin: auto;
  text-align: center;
  margin-top: 90px;
  font-family: "Montserrat", sans-serif;
}

.container h2 {
  text-align: center;
  font-size: 26px;
  margin-bottom: 30px;
}

.wrapper.darkMode .container h2 {
  color: #fff;
}

.lightButton {
  position: absolute;
  top: 20px;
  right: 40px;
}

.lightButton i {
  font-size: 40px;
  cursor: pointer;
}

.form {
  width: 100%;
  margin: auto;
  background: #f2f2f2;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  column-gap: 10px;
}

.wrapper.darkMode .form {
  background:d #222;
}

.form input {
  width: 100%;
  border: none;
  padding: 10px 12px;
  border-radius: 0px;
  transition: all 300ms ease-in-out;
  font-size: 16px;
}
.formgroup input {
  width: 100%;
  border: none;
  padding: 10px 12px;
  border-radius: 0px;
  transition: all 300ms ease-in-out;
  font-size: 16px;
  outline: none;
}
button{
  border-radius: 5px;
}
.form__group{
  padding:0;
  width: 100%;
}
.form__group textarea{
  padding: 0px;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  outline: none;
}
.wrapper.darkMode .form input {
  background: #222;
  color: #fff;
  border-bottom: 1px solid #555;
  border-radius: 0px;
}

.form input:focus {
  outline: none;
  padding-left: 18px;
}

.wrapper.darkMode .form input:focus {
  outline: none;
}

.form button {
  border: none;
  padding: 12px 22px;
  background: #f97729;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}

.todolist { 
  width: 70%;
  margin: 40px auto;
}
.todolisted { 
  width: 100%;
  margin: 40px auto;
}

.todoitem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f2f2f2;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.wrapper.darkMode .todoitem {
  background: #222;
}

.todoitem h5 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  border-radius: 10px;
  margin-bottom: 0px;
}

.wrapper.darkMode .todoitem h5 {
  color: #fff;
}

.todoitem div {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.todoitem div i {
  background: #d7d7d7;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.todoitem div i.hasEdit {
  background: #feffd9;
}

.todoitem div i.hasDelete {
  background: #fbcebe;
}
.round{
  padding: 5px;
  background-color: #d7d7d7;
  border-radius: 20px;
}
.shape{
  width: 60px;
  border-radius: 20px;
  background-color: #d7d7d7;
}
error {
  font-size: 10px;
  color: #ff6774;
}
.sticky-default {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 40;
}

@media (min-width: 640px) {
  .sticky-default {
    position: relative;
  }
 
}
@media (max-width: 640px) {
  #responsive{
    display: none;
  }
  #responsiveModal{
    width: 80%;
    position: relative;
    left: 30px;
    height: 100%;
   }
   .container {
    width: 90%;
  }
  .form button {
     padding: 7px;
   
  }
  .form input {
    width: 100%;
    border: none;
    padding: 5px 7px;
    border-radius: 0px;
    transition: all 300ms ease-in-out;
    font-size: 16px;
  }
 
}
 

/* DASHBOARD */
/* DashboardMainNavigation */
#dMainNav a.active {
  @apply text-[#f97729];
}
